import styles from '../styles/custom404.module.scss';

export default function Custom404() {
  return (
    <>
      <div className={styles.custom_404}>
        <h1 className={styles.heading}>Page does not exist!</h1>
        <p className={styles.message}>Sorry, the page you were looking for could not be found.</p>
      </div>
    </>
  );
}
